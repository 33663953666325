/* Remover os controles do carousel e setas */
.carousel .thumbs-wrapper,
.carousel-status {
  display: none;
}
.carousel.carousel-slider .control-arrow:hover {
  background: transparent;
}

/* Ajustar o container principal */
.parent-loader {
  background-color: #272727;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  overflow: hidden; /* Para garantir que nada saia da tela */
}

/* Fonte padrão */
.font-link {
  font-family: "Manrope", sans-serif;
  font-size: 1em; /* Usar unidade relativa */
}

/* Animações e transformações de botões */
.c-btn span:first-child {
  transform: translateX(-101%);
  transition: transform 0.3s ease-in;
}
.c-btn:hover span {
  transform: translateX(0);
}

/* Ajustar os ícones sociais */
.socials .footer-div span:last-child {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-100%);
}
.socials span {
  transition: transform 0.2s ease-out;
}
.socials .footer-div:hover span:first-child {
  transform: translateY(100%);
}
.socials .footer-div:hover span:last-child {
  transform: none;
}

/* Animação de ilustração */
.boy-illustration {
  transform-origin: center;
  transform-style: preserve-3d;
  transform: scale(1);
  transition: transform 225ms ease-out;
}

.boy-illustration:hover {
  transform: scale(1.1); /* Aumenta o elemento */
  transition: transform 380ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
}


/* Botão de rolar para o topo */
.scrollTop {
  position: fixed;
  width: auto;
  bottom: 20px;
  right: 30px;
  height: 20px;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
}
.scrollTop:hover {
  opacity: 1;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

/* Ajustes para telas menores */
@media screen and (max-width: 768px) {
  .contact-btn {
    display: none;
  }
  .font-link {
    font-size: 0.9em; /* Ajustar a fonte para telas menores */
  }
}

@media screen and (max-width: 400px) {
  :placeholder-shown {
    font-size: 12px;
  }
  .font-link {
    font-size: 0.8em; /* Ajustar a fonte para telas menores */
  }
}

/* Ajustes para telas intermediárias */
@media screen and (min-width: 424px) and (max-width: 500px) {
  .submit-btn {
    top: 7%;
  }
}
