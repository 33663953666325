/* Default styling for the mascot */
.mascote {
    width: 600px;
    margin-top: -80px;
  }
  
  /* Responsiveness for 1920x1080 */
  @media screen and (min-width: 1920px) and (max-height: 1080px) {
    .mascote {
      margin-top: 20px; /* Adjust downward */
    }
  }

  /* Hide mascot on mobile devices */
@media screen and (max-width: 768px) {
    .mascote {
      display: none; /* Hide mascot */
    }
  }
  